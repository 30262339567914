import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import gstyles from '@vl/gstyles';

export const View8 = ({ title, component }) => {
  return (
    <DIV forceCtx>
      <ModalProvider>
        <div
          onClick={
            !!_.has(component, 'render')
              ? () => {
                  ctx.apply('modalModel.show', () => {
                    return <DIV>{component.render()}</DIV>;
                  });
                }
              : null
          }
        >
          <div className="flex items-center cursor-pointer">
            <div>
              {gstyles.icons({
                name: 'cloud-upload',
                size: 24,
                fill: gstyles.colors.sub,
              })}
            </div>
            <div className="pl-2 md:underline text-sub text-sm md:text-base">{title}</div>
          </div>
        </div>
      </ModalProvider>
    </DIV>
  );
};

export default displayName(View8);
