const _ = require('lodash');
const moment = require('moment');

function ExcelDateParsing(excel_date, time = false) {
  let dayTime = excel_date % 1;
  let meridiem = 'AMPM';
  let hour = Math.floor(dayTime * 24);
  let minute = Math.floor(Math.abs(dayTime * 24 * 60) % 60);
  let second = Math.floor(Math.abs(dayTime * 24 * 60 * 60) % 60);
  hour >= 12 ? (meridiem = meridiem.slice(2, 4)) : (meridiem = meridiem.slice(0, 2));
  hour > 12 ? (hour = hour - 12) : (hour = hour);
  hour = hour < 10 ? '0' + hour : hour;
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  let daytime = '' + hour + ':' + minute + ':' + second + ' ' + meridiem;
  return time
    ? daytime
    : new Date(0, 0, excel_date, 0, -new Date(0).getTimezoneOffset(), 0).toLocaleDateString(navigator.language, {}) +
        ' ' +
        daytime;
}

const excelDateToJSDate = (date) => {
  const JSDate = ExcelDateParsing(date);
  return moment(JSDate).format();
};

exports.excelDateToJSDate = excelDateToJSDate;
