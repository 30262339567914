import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import _ from 'lodash';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';

const bindData = bindings({
  B2BViewMemberModal: {
    rules: [
      [
        'data',
        {
          data: {
            recordData: hook((ctx) => {
              return ctx.get('@item');
            }),
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const account_id = ctx.apply('accountModel.getAccountId');
              const validationSchema = React.useMemo(() => {
                return Yup.object({});
              }, []);
              const avatarUrl = !ctx.get('recordData.member_profile.avatar_url')
                ? ''
                : [
                    {
                      url: ctx.get('recordData.member_profile.avatar_url'),
                      status: 'success',
                    },
                  ];

              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  account_id,
                  user_id: ctx.get('recordData.user_id'),
                  first_name: ctx.get('recordData.member_profile.first_name'),
                  last_name: ctx.get('recordData.member_profile.last_name'),
                  avatar_url: avatarUrl,
                  date_of_birth: ctx.get('recordData.member_profile.date_of_birth'),
                  place_of_birth: ctx.get('recordData.member_profile.place_of_birth'),
                  email: ctx.get('recordData.member_profile.email'),
                  phone: ctx.get('recordData.member_profile.phone'),
                  note: ctx.get('recordData.member_profile.note'),
                  roles: _.map(ctx.get('recordData.member_roles'), (role) => {
                    return {
                      id: _.get(role, 'role.role_id'),
                    };
                  }),
                },
                validateOnChange: true,
                validateOnBlur: false,
                onSubmit: async (values, actions) => {
                  try {
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema,
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !form.isSubmitting && form.dirty && form.isValid,
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
