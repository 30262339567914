import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import MemberModel from '@uz/unitz-models/B2BMemberModel';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import _ from 'lodash';
import useFilterForm from '@vl/hooks/useFilterForm';
import useRoute from '@vl/hooks/useGbRouteDe';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import gstyles from '@vl/gstyles';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import B2BEditMemberModal from '@uz/unitz-tool-components/B2BEditMemberModal';
import B2BViewMemberModal from '@uz/unitz-tool-components/B2BViewMemberModal';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import ActionMenu from '@uz/unitz-components-web/ActionMenu';
import DIV from '@vl/redata/DIV.macro';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            filterForm: hook((ctx) => {
              const route = useRoute();
              const locale = _.get(route.getPageContext(), 'lang', '');
              const routeParams = route.getParams();
              const account_slug = _.get(routeParams, 'slug');
              const search_data = _.get(routeParams, 'search', '');
              const roles = _.compact(_.castArray(_.get(routeParams, 'roles', '')));
              const status = _.get(routeParams, 'status', '');
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  roles: roles,
                  status: status,
                  search: search_data,
                },

                onSubmit: async (values, actions) => {
                  try {
                    const memberFilterPagingModel = ctx.apply('REF.getRef', 'MemberPagingModel');
                    if (memberFilterPagingModel) {
                      await memberFilterPagingModel.onLoadPage(0);
                    }
                    route.navigate(
                      route.to(
                        !!account_slug ? `/${locale}/${account_slug}/members` : `/${locale}/accounts/me/members`,
                        {
                          ..._.omit(form.values, ['']),
                        }
                      )
                    );
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                clearFilter: async () => {
                  // route.navigateExternal(route.to(`/${locale}/accounts/me/members`));
                  await form.resetForm();
                  form.submitForm();
                },
                handleKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    form.handleSubmit();
                  }
                },
              });

              return form;
            }),

            getFilterArgs: hook((ctx) => {
              const statusVars = {
                active: true,
                disabled: false,
                all: '',
              };
              return () => {
                const values = ctx.get('filterForm.values', {});
                const status = _.get(values, 'status', '');
                const args = {
                  roles: _.castArray(_.get(values, 'roles', '')).join(','),
                  search: _.get(values, 'search', ''),
                  ...(status ? { status: _.get(statusVars, status, '') } : {}),
                };
                if (status === 'all') _.unset(args, 'status');
                return args;
              };
            }),

            handleChangeSubmit: hook((ctx) => {
              return React.useMemo(() => _.debounce(() => ctx.apply('filterForm.handleSubmit'), 599), []);
            }),

            roles: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const account_data = await client.request(
                      gql`
                        query MyQuery($id: uuid!) {
                          b2b_account_by_pk(id: $id) {
                            roles {
                              name
                              id
                              role_id
                              description
                              role_permissions {
                                acl_subject
                                permission {
                                  name
                                  description
                                }
                              }
                            }
                          }
                        }
                      `,
                      { id: account_id }
                    );
                    return account_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id]
              );
              return _.get(data, 'b2b_account_by_pk.roles', []);
            }),

            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModel = React.useMemo(() => {
                const condition = `where: {
                  account_id: {_eq: "${account_id}"}
                  member_roles: {role_id: {_eq: "admin"}}
                }`;
                const dataQuery = `
                  b2b_search_member (args: $args, ${condition}, order_by: {created_at: desc_nulls_last}){
                    id
                    is_active
                    user_id
                    member_profile {
                      id
                      first_name
                      last_name
                      email
                      avatar_url
                      phone
                      date_of_birth
                      place_of_birth
                      phone
                      note
                    }
                    user {
                      profile {
                        display_name
                        avatar_url
                        email
                      }
                    } 
                    member_roles{
                      role {
                        role_id
                        name
                      }
                    }
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  Model: MemberModel,
                  dataQuery,
                  aggQuery: `
                    b2b_search_member_aggregate (args: $args, ${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 10,
                  varsList: '$args: b2b_search_member_args!',
                  getVars: () => {
                    return {
                      args: ref.current.getFilterArgs(),
                    };
                  },
                });
                return pagingModel;
              }, [ref, account_id]);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                hasFilterArgs: () => !!_.compact(_.values(ref.current.getFilterArgs())).length,
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };
              ctx.apply('REF.setRef', 'MemberPagingModel', pagination);
              return pagination;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return (
                      <div className="text-xs text-center">
                        {ctx.get('paginationModel.page') * ctx.get('paginationModel.pageSize') + index + 1}
                      </div>
                    );
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.full_name')}
                    </div>
                  ),
                  // sorter: {
                  //   toQuery: (sorter) => {
                  //     return { profile: { display_name: useFilterForm.SORTER_MAPPING[sorter.order] } };
                  //   },
                  // },
                  // defaultSortOrder: useFilterForm.getSortOrder('user.profile.display_name'),
                  render: (val) => {
                    return (
                      <div>
                        <ModalProvider>
                          <div
                            className="identify-container cursor-pointer"
                            onClick={() => {
                              ctx.apply('modalModel.show', () => {
                                return (
                                  <DIV>
                                    {ctx.debug(() => {
                                      ctx.set('@item', val);
                                    })}
                                    <B2BViewMemberModal />
                                  </DIV>
                                );
                              });
                            }}
                          >
                            <div className="table-cell align-top">
                              <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                            </div>

                            <div
                              style={{ verticalAlign: 'middle' }}
                              className="table-cell text-brandb500 underline align-middle pl-5 text-xs"
                            >
                              {MemberFormatter.fullName(ctx)(val)}
                            </div>
                          </div>
                        </ModalProvider>
                      </div>
                    );
                  },
                  require: true,
                  width: 300,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.phone_number')}
                    </div>
                  ),
                  width: 120,
                  key: 'phoneNumber',
                  // sorter: {
                  //   toQuery: (sorter) => {
                  //     return { profile: { phone_number: useFilterForm.SORTER_MAPPING[sorter.order] } };
                  //   },
                  // },
                  defaultSortOrder: useFilterForm.getSortOrder('member_profile.phone'),

                  render: (val) => <div className="text-xs">{MemberFormatter.profilePhoneNumber(ctx)(val) || ''}</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.email')}
                    </div>
                  ),
                  width: 160,
                  key: 'email',
                  // sorter: {
                  //   toQuery: (sorter) => {
                  //     return { profile: { email: useFilterForm.SORTER_MAPPING[sorter.order] } };
                  //   },
                  // },

                  render: (val) => <div className="text-xs">{MemberFormatter.email(ctx)(val) || ''}</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.role')}
                    </div>
                  ),
                  width: 80,
                  render: (val) => (
                    <div className="text-xs">
                      {_.map(_.get(val, 'member_roles', []), (item) => {
                        return ctx.apply('i18n.t', `ZoomToolMember.Role.${_.get(item, 'role.role_id', '')}`);
                      }).join(', ')}
                    </div>
                  ),
                },
                // {
                //   title: (
                //     <div className="text-sub font-semibold text-xs">
                //       {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}
                //     </div>
                //   ),
                //   width: 80,
                //   render: (val) => {
                //     return (
                //       <div className="text-xs">
                //         {!!_.get(val, 'is_active')
                //           ? ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.active')
                //           : ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.disabled')}
                //       </div>
                //     );
                //   },
                // },

                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.action')}
                    </div>
                  ),
                  width: 120,
                  render: (val) => {
                    return (
                      <ModalProvider>
                        <div
                          className="underline text-xs text-brandb500 cursor-pointer text-center"
                          onClick={() => {
                            ctx.apply('modalModel.show', () => {
                              return (
                                <DIV>
                                  {ctx.debug(() => {
                                    ctx.set('@item', val);
                                  })}
                                  <B2BEditMemberModal />;
                                </DIV>
                              );
                            });
                          }}
                        >
                          {ctx.apply('i18n.t', 'ZoomToolMember.Table.Action.edit')}
                        </div>
                      </ModalProvider>
                      // <ActionMenu>
                      //   <ActionMenu.Item>
                      //     <ModalProvider>
                      //       <div
                      //         className="underline text-xs text-brandb500 cursor-pointer text-center"
                      //         onClick={() => {
                      //           ctx.apply('modalModel.show', () => {
                      //             return (
                      //               <DIV>
                      //                 {ctx.debug(() => {
                      //                   ctx.set('@item', val);
                      //                 })}
                      //                 <B2BEditMemberModal />;
                      //               </DIV>
                      //             );
                      //           });
                      //         }}
                      //       >
                      //         {ctx.apply('i18n.t', 'ZoomToolMember.Table.Action.edit')}
                      //       </div>
                      //     </ModalProvider>
                      //   </ActionMenu.Item>
                      // </ActionMenu>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblMembers'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblMembers');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
