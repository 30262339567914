import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import { useRenderProps } from '@vl/hooks/useRenderProps';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import B2BEditMemberModal from '@uz/unitz-tool-components/B2BEditMemberModal';
import ModalProvider from '@uz/unitz-providers/ModalProvider';

const renderTeacherItem = useRenderProps(
  ({ item, index }) => {
    return (
      <DIV forceCtx>
        {ctx.debug(() => {
          ctx.set('@item', item);
          ctx.set('@index', index);
        })}
        <div className="px-8 py-6 w-full bg-white500 rounded-lg">
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col>
              <div className="mb-4">
                <Avatar size={60} src={MemberFormatter.avatarUrl(ctx)(item)} />
              </div>
              <div className="my-1">
                <span className="text-sub font-semibold text-center text-xs mr-1">
                  {ctx.apply('i18n.t', 'ZoomToolMember.Role.teacher')}:
                </span>
                <span className="text-xs">{MemberFormatter.fullName(ctx)(item)}</span>
              </div>
              <div className="my-1">
                <span className="text-sub font-semibold text-center text-xs mr-1">
                  {ctx.apply('i18n.t', 'ZoomToolMember.Table.email')}:
                </span>
                <span className="text-xs">{MemberFormatter.email(ctx)(item) || ''}</span>
              </div>

              <div className="my-1">
                <span className="text-sub font-semibold text-center text-xs mr-1">
                  {ctx.apply('i18n.t', 'ZoomToolMember.Table.role')}:
                </span>
                <span className="text-xs">
                  {_.map(_.get(item, 'member_roles', []), (role) => {
                    return ctx.apply('i18n.t', `ZoomToolMember.Role.${_.get(role, 'role.role_id', '')}`);
                  }).join(', ')}
                </span>
              </div>

              <div className="my-1">
                <span className="text-sub font-semibold text-center text-xs mr-1">
                  {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}:
                </span>
                <span className="text-xs">
                  {_.get(item, 'is_active')
                    ? ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.active')
                    : ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.disabled')}
                </span>
              </div>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <ModalProvider>
                    <Button
                      block
                      size="medium"
                      type="primary"
                      onClick={() => {
                        ctx.apply('modalModel.show', () => {
                          return (
                            <DIV>
                              <B2BEditMemberModal />;
                            </DIV>
                          );
                        });
                      }}
                    >
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.Action.edit')}
                    </Button>
                  </ModalProvider>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </DIV>
    );
  },
  ({ item, index }) => [index, _.get(item, 'id')]
);

export default renderTeacherItem;
