import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import _ from 'lodash';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import useScript from '@vl/hooks/useScript';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { notification } from 'antd';
import moment from 'moment';
import { PHONE_NUMBER } from '@vl/mod-utils/validateUtil';
import { excelDateToJSDate } from '@vl/mod-utils/excelDateToJSDate';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            import_form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const account_id = ctx.apply('accountModel.getAccountId');
              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  members: [],
                  file: '',
                },
                validateOnChange: true,
                validateOnBlur: false,
                onSubmit: async (values, actions) => {
                  try {
                    const members = values.members;
                    for (const member of members) {
                      const payload = {
                        account_id,
                        first_name: _.get(member, 'member_profile.first_name', ''),
                        last_name: _.get(member, 'member_profile.last_name', ''),
                        email: _.get(member, 'member_profile.email', ''),
                        phone: `${_.get(member, 'member_profile.phone', '')}`,
                        date_of_birth: _.get(member, 'member_profile.date_of_birth', ''),
                        place_of_birth: _.get(member, 'member_profile.place_of_birth', ''),
                        // roles: _.map(_.get(member, 'member_roles', []), (role) => {
                        //   return { id: _.get(role, 'role.role_id', '') };
                        // }),
                        roles: [
                          {
                            id: 'admin',
                          },
                        ],
                      };
                      const res = await fbFnsClient.getClient().post('triggers-tool-createMember', { ...payload });
                      await new Promise((resolve) => setTimeout(resolve, 1000 * 5));
                    }

                    await ctx.apply('modalModel.hide');

                    ctx.apply('paginationModel.onRefresh');
                  } catch (err) {
                    notification.error({
                      message: ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.Notification.add_error'),
                    });
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !form.isSubmitting && form.dirty && form.isValid,
                getUploaderProps: AssetModelFormatter.getSingleUploaderProps(ctx, form),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              const loadedStatus = useScript('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.3/xlsx.full.min.js');

              React.useEffect(() => {
                const file = _.get(form, 'values.file.0');
                const url = _.get(file, 'url', '');

                if (loadedStatus === 'ready') {
                  async function Get_XLSX_As_Workbook_From_URL(url) {
                    const arrayBuffer = await new Promise((resolve, reject) => {
                      const oReq = new XMLHttpRequest();
                      oReq.open('GET', url, true);
                      oReq.responseType = 'arraybuffer';
                      oReq.onload = () => resolve(oReq.response);
                      oReq.onerror = reject;
                      oReq.send();
                    });
                    const data = new Uint8Array(arrayBuffer);
                    var arr = new Array();
                    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
                    let bstr = arr.join('');
                    const workBook = XLSX.read(bstr, {
                      type: 'binary',
                    });
                    return workBook;
                  }
                  (async () => {
                    const workBook = await Get_XLSX_As_Workbook_From_URL(url);

                    const first_sheet_name = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[first_sheet_name];
                    let reso = XLSX.utils.sheet_to_json(workSheet, {
                      raw: true,
                    });

                    let verifyData;

                    const validationSchema = Yup.object().shape({
                      first_name: Yup.string().required(),
                      last_name: Yup.string().required(),
                      email: Yup.string()
                        .email()
                        .required(),
                      phone: Yup.string().required(),
                      date_of_birth: Yup.string().required(),
                      // role: Yup.string().required(),
                      place_of_birth: Yup.string().required(),
                    });

                    const fieldRoot = _.map(reso, (item) => {
                      return {
                        first_name: _.get(item, 'First Name', undefined),
                        last_name: _.get(item, 'Last Name', undefined),
                        phone: _.get(item, 'Phone', undefined),
                        email: _.get(item, 'Email', undefined),
                        // role: _.get(item, 'Role', undefined),
                        date_of_birth: _.get(item, 'Date Of Birth', undefined),
                        place_of_birth: _.get(item, 'Place Of Birth', undefined),
                      };
                    });

                    const isValidDocs = _.some(fieldRoot, (item) => {
                      const isValid = validationSchema.isValidSync(item);
                      return isValid;
                    });

                    if (isValidDocs) {
                      const memberList = _.filter(fieldRoot, (item) => {
                        const isValid = validationSchema.isValidSync(item);
                        return isValid;
                      });

                      verifyData = _.map(memberList, (item, index) => {
                        const dOBExcel = _.get(item, 'date_of_birth');
                        const dOBFormat = moment(dOBExcel, 'DD/MM/YYYY');
                        return {
                          no: index + 1,
                          member_profile: {
                            first_name: _.get(item, 'first_name', ''),
                            last_name: _.get(item, 'last_name', ''),
                            phone: _.get(item, 'phone', ''),
                            email: _.get(item, 'email', ''),
                            date_of_birth: _.isNumber(_.get(item, 'date_of_birth', ''))
                              ? excelDateToJSDate(_.get(item, 'date_of_birth', ''))
                              : moment(dOBFormat).format(),
                            place_of_birth: _.get(item, 'place_of_birth', ''),
                          },
                          // member_roles: [
                          //   {
                          //     role: {
                          //       role_id: _.lowerCase(_.get(item, 'role', '')),
                          //       name: _.get(item, 'role', ''),
                          //     },
                          //   },
                          // ],
                        };
                      });
                      form.setFieldValue('members', [...verifyData]);
                    }

                    form.setErrors({ import: 'excel error' });

                    return verifyData;
                  })();
                }
              }, [form.values.file[0]]);
              return form;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs text-center">{_.get(val, 'no', '')}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.full_name')}
                    </div>
                  ),

                  render: (val) => {
                    return <div className="table-cell text-main text-xs">{MemberFormatter.fullName(ctx)(val)}</div>;
                  },
                  require: true,
                  width: 300,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.email')}
                    </div>
                  ),
                  width: 160,
                  key: 'email',

                  render: (val) => <div className="text-xs">{MemberFormatter.email(ctx)(val) || ''}</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.phone_number')}
                    </div>
                  ),
                  width: 120,
                  key: 'phoneNumber',

                  render: (val) => <div className="text-xs">{MemberFormatter.profilePhoneNumber(ctx)(val) || ''}</div>,
                },

                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.place_of_birth')}
                    </div>
                  ),
                  width: 120,
                  key: 'poB',

                  render: (val) => <div className="text-xs">{_.get(val, 'member_profile.place_of_birth', '')}</div>,
                },

                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.date_of_birth')}
                    </div>
                  ),
                  width: 120,
                  key: 'doB',

                  render: (val) => (
                    <div className="text-xs">
                      {moment(_.get(val, 'member_profile.date_of_birth')).format('YYYY/MM/DD')}
                    </div>
                  ),
                },

                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.role')}
                    </div>
                  ),
                  width: 80,
                  render: (val) => (
                    <div className="text-xs">
                      {/* {_.map(_.get(val, 'member_roles', []), (item) => {
                        return ctx.apply('i18n.t', `ZoomToolMember.Role.${_.get(item, 'role.role_id', '')}`);
                      }).join(', ')} */}
                      {ctx.apply('i18n.t', 'ZoomToolMember.Role.admin')}
                    </div>
                  ),
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblImportMember'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('import_form.values.members');
              const tableName = ctx.get('tableName', 'tblImportMember');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
