import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  memberFilterMenu: {
    rules: [
      [
        'data',
        {
          data: {
            statusOptions: hook((ctx) => {
              const status = [
                {
                  value: 'all',
                  label: ctx.apply('i18n.t', 'ZoomToolFilter.Status.all'),
                },
                {
                  value: 'active',
                  label: ctx.apply('i18n.t', 'ZoomToolFilter.Status.active'),
                },
                {
                  value: 'disabled',
                  label: ctx.apply('i18n.t', 'ZoomToolFilter.Status.disabled'),
                },
              ];
              return status;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
