import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import MemberFilterMenu from '@uz/unitz-tool-components/MemberFilterMenu';
import B2BAddMemberModal from '@uz/unitz-tool-components/B2BAddMemberModal';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { Button } from '@uz/unitz-components-web/Button';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import CustomTable from '@uz/unitz-components-web/CustomTable';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import AdminListX from '@uz/unitz-tool-components/AdminListX';
import Paginator from '@uz/unitz-components-web/Paginator';
import GuideBubble from '@uz/unitz-tool-components/GuideBubble';
import MemberImportButton from '@uz/unitz-tool-components/MemberImportButton';
import B2BImportAdminModal from '@uz/unitz-tool-components/B2BImportAdminModal';

const UserHeader = () => {
  return (
    <DIV>
      <ResponsiveProps
        xs={{
          renderer: () => (
            <div className="flex flex-col justify-between py-6">
              <div className="mb-4 text-brandb500 font-semibold text-2xl">
                {ctx.apply('i18n.t', 'ZoomToolMember.title')}
              </div>
              <div className="w-auto text-right">
                <Row justify="end" align="middle" gutter={[8, 8]}>
                  <Col>
                    <DIV forceCtx>
                      <ModalProvider>
                        <Button
                          type="primary"
                          size="medium"
                          onClick={() => {
                            ctx.apply('modalModel.show', () => {
                              return <B2BAddMemberModal />;
                            });
                          }}
                          block
                        >
                          <div className="flex items-center">
                            <div className="text-white500 font-semibold">
                              {ctx.apply('i18n.t', 'ZoomToolMember.add_user_button')}
                            </div>
                            <div>
                              {gstyles.icons({
                                name: 'plus',
                                size: 14,
                                fill: gstyles.colors.white500,
                                className: 'mx-2',
                              })}
                            </div>
                          </div>
                        </Button>
                      </ModalProvider>
                    </DIV>
                  </Col>
                  <Col>
                    <MemberImportButton
                      title={ctx.apply('i18n.t', 'ZoomToolMember.Action.import_users')}
                      component={{ render: () => <B2BImportAdminModal /> }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          ),
        }}
        md={{
          renderer: () => (
            <Row align="middle" justify="space-between" className="w-full py-6" gutter={[8, 8]}>
              <Col>
                <div className="text-main font-semibold text-base">{ctx.apply('i18n.t', 'ZoomToolMember.title')}</div>
              </Col>
              <Col>
                <Row align="middle" gutter={[8, 8]}>
                  <Col>
                    <MemberImportButton
                      title={ctx.apply('i18n.t', 'ZoomToolMember.Action.import_users')}
                      component={{ render: () => <B2BImportAdminModal /> }}
                    />
                  </Col>
                  <Col>
                    <ModalProvider>
                      <Button
                        type="primary"
                        size="medium"
                        onClick={() => {
                          ctx.apply('modalModel.show', () => {
                            return <B2BAddMemberModal />;
                          });
                        }}
                        block
                      >
                        <div className="flex items-center">
                          <div className="text-white500 font-semibold">
                            {ctx.apply('i18n.t', 'ZoomToolMember.add_user_button')}
                          </div>
                          <div>
                            {gstyles.icons({
                              name: 'plus',
                              size: 14,
                              fill: gstyles.colors.white500,
                              className: 'mx-2',
                            })}
                          </div>
                        </div>
                      </Button>
                    </ModalProvider>
                  </Col>
                </Row>
              </Col>
            </Row>
          ),
        }}
      >
        {(resProps) => resProps && resProps.renderer()}
      </ResponsiveProps>
    </DIV>
  );
};

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="m-4">
          {ctx.debug(() => {
            const roles = ctx.get('roles');
            ctx.set('roleOptions', roles);
          })}
          <MemberFilterMenu />
          <UserHeader />
          {renderPropsComposer(
            {
              matcher: (props) =>
                !_.get(props, 'items.length') &&
                !_.get(props, 'isLoading') &&
                !ctx.apply('paginationModel.hasFilterArgs'),
              render: () => (
                <>
                  <Row justify="center" className="py-16">
                    <Col span={12}>
                      <div className="flex flex-col items-center justify-center">
                        <div className="mb-4">
                          {gstyles.icons({
                            name: 'no-result',
                            size: 152,
                          })}
                        </div>
                        <div className="mb-6 text-center text-2xl font-semibold text-sub">
                          {ctx.apply('i18n.t', 'ZoomToolMember.empty_admin')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <GuideBubble />
                </>
              ),
            },
            {
              matcher: (props) => !!_.get(props, 'items.length') || ctx.apply('paginationModel.hasFilterArgs'),
              render: () => (
                <>
                  <ResponsiveProps
                    xs={{
                      renderer: () => (
                        <>
                          <AdminListX />
                          <div className="my-6">
                            <Paginator />
                          </div>
                        </>
                      ),
                    }}
                    md={{
                      renderer: () => (
                        <CustomTable
                          columns={ctx.get('tableColumn.columns')}
                          dataSource={ctx.get('tableColumn.dataSource', [])}
                          rowKey={(rec) => _.get(rec, 'id')}
                          loading={ctx.get('paginationModel.isLoading')}
                          pagination={ctx.get('tableColumn.pagination')}
                          onChange={ctx.get('paginationModel.onChange')}
                          size="middle"
                        />
                      ),
                    }}
                  >
                    {(resProps) => resProps && resProps.renderer()}
                  </ResponsiveProps>
                </>
              ),
            },
            () => null
          )(ctx.get('paginationModel'))}
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
